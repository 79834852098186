@import 'const.sass'

.Tabs
	display: inline-block

	.Tab
		border: 1px solid $color-light-grey
		border-right: 0
		border-radius-top-left: 3px
		font-size: 1.4rem
		padding: 0.4rem 1.2rem
		line-height: 2.2rem
		color: $color-grey-text
		background: $color-white
		font-weight: 500
		transition: all 0.2s ease
		cursor: pointer
		height: 36px

		&:first-of-type
			border-top-left-radius: 3px
			border-bottom-left-radius: 3px

		&:last-of-type
			border-right: 1px solid $color-light-grey
			border-top-right-radius: 3px
			border-bottom-right-radius: 3px
		&.Tab--clear
			background: transparent

		&.Tab--active
			background: $color-blue
			color: $color-white

.TabPanel__Container
	&:focus
		outline: 0
