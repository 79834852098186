@import "const.sass"

.DataTableContainer
	overflow: auto
	width: 100%
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04)
	border-radius: 3px
	border: 1px solid $color-light-grey
	background: $color-white

.DataTable
	width: 100%
	border-collapse: collapse
	white-space: nowrap
	overflow: auto


	.DataTable__header
		text-transform: uppercase
		text-align: left
		border-bottom: 1px solid $color-light-grey
		color: $color-dark-grey

	.DataTable__header-item
		padding: 1.6rem
		color: $color-grey-text
		text-transform: uppercase
		font-weight: bold
		font-size: 11px
		line-height: 18px

	.DataTable__header-item--selection
		width: 2rem

	.DataTable__content--common
		cursor: pointer
		.IconAction
			background: $color-white
			&:disabled
				background: $color-light-grey
	.DataTable__content-row
		color: $color-dark-grey
		border-bottom: 1px solid $color-light-grey
		&:nth-child(odd)
			background: $color-lighter-grey
		&:hover
			background: $color-light-grey
			.DataTable__content-item
				color: $color-black

	.DataTable__content-item
		padding: 1rem
		padding-left: 1.6rem
		color: $color-grey-text
		font-weight: 500
		font-size: 13px
		line-height: 22px

		&.DataTable__content-item-blue
			color: $color-blue
		.DataTable__hoverActions
			svg
				width: 15px
				height: 15px

	.DataTable__content-error
		color: $color-dark-red
		border-bottom: 1px solid rgba(243, 42, 42, 0.2)
		background: $color-light-red
		&:hover
			background: #f7b4b4
		.DataTable__content-item
			color: $color-darker-red

	.DataTable__sort
		margin-left: 1rem

	.DataTable__sort--asc, .DataTable__sort--desc
		cursor: pointer
		margin-right: 0.2rem

	.DataTable__footer-item
		padding: 1.6rem

	.Checkbox, .Checkbox__container
		margin: 0

.DataTableFooter
	padding: 1.6rem
	display: flex
	align-items: center
	justify-content: space-between
	background-color: $color-white

	.DataTableFooter__paginationContainer
		display: flex
		align-items: flex-start
		flex-direction: column
		color: $color-grey-text
		font-weight: 500
		font-size: 0.8em
		line-height: 22px
		+over-500px()
			align-items: center
			flex-direction: row
		.DataTableFooter__pagination_icons
			display: flex
		.DataTableFooter__paginationContainer__Icons
			display: flex
			margin-bottom: 1rem
			+over-500px()
				margin-bottom: 0
				

	.DataTableFooter__RowsCustomizer
		display: flex
		align-items: center
		flex-direction: column
		+over-500px()
			flex-direction: row
		.DataTableFooter__RowsCustomizer__SelectBox
			min-width : 70px
		.DataTableFooter__RowsCustomizer__Title
			margin-left: 1rem

	.DataTableFooter__page
		color: $color-darker-grey
		font-weight: 500

.MobileListContainer__ActionsContainer
	margin: 2rem 0
.MobileListContainer
	list-style: none
	background-color: $color-white
	border: 2px solid $color-light-grey
	border-bottom: 0.5px solid $color-light-grey
	.DataTable__content--container
		width: 100%
	.DataTable__content--common
		.IconAction
			background: $color-white
	.DataTable__content-row
		display: flex
		padding: 1rem
		color: $color-dark-grey
		border-bottom: 1px solid $color-light-grey

		&:nth-child(odd)
			background: $color-lighter-grey

		&:hover
			background: $color-light-grey
			color: $color-darker-grey

	.DataTable__content-error
		display: flex
		padding: 1rem
		color: $color-dark-red
		border-bottom: 1px solid $color-dark-red
		background: $color-light-red
		&:hover
			background: #f7b4b4
		.DataTable__content--mobileContentContainer
			p
				color: $color-darker-red
.DataTableMobileActionsContainer
	display: flex
	justify-content: space-between
	align-items: center
	width: 100%
	.DataTableMobileActionsContainer__Checkbox__Label
		font-weight: 500
		font-size: 13px
		line-height: 22px
		color: $color-blue
	.Pagination
		display: flex
		align-items: center
		.Pagination__Content
			color: $color-darker-grey-text
			.Pagination__Content__Span
				color: $color-black
		.Pagination__Icon
			margin-right: 1rem
	&.DataTableMobileActionsContainer--nocheckbox
		justify-content: flex-end

.DataTableMobileActionsSmallScreen
	flex-direction: column

.DataTable__content--boldheader
	color: $color-blue
	font-weight: bold
	font-size: 13px
	line-height: 22px
.DataTable__content--boldheader--error
	color: $color-darker-red
	font-weight: bold
	font-size: 13px
	line-height: 22px
.DataTable__content--mobileCheckbox
	display: flex
	margin-right: 1rem
	margin-top: 1rem
.DataTable__content--mobileContentContainer
	.DataTable__content--mobileContentContainer--body
		font-weight: 600

.FooterActions--container
	display: flex
	position: fixed
	bottom: 0
	width: 100vw
	background: $color-white
	overflow-x: scroll
	padding: 2.1rem 0 2.1rem 0
	.FooterActions--container--close
		margin: 0 1rem 0 2rem

.Footer__Pagination__Icon
	width: 30px
	height: 30px
	svg
		width: 15px
		height: 15px
