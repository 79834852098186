@import "const.sass"

.Analytics__Loading__Container
    margin: 2rem

.Analytics__Loading__header
  margin-bottom: 1rem
  margin-top: 1rem

.Analytics__Telco__Loading__Container
  display: grid
  grid-template-columns: auto auto
  width: 100%
  .Telco
    margin: 0.5rem
    height: 30rem


