@import 'const.sass'

.AnimatedMessageContainer
	position: fixed
	bottom: 50px
	left: 50%
	transform: translateX(-50%)
	animation-duration: 6s
	animation-fill-mode: forwards
	animation-name: fadeInFadeOut
	z-index: 10000
	display: table
	top: 100px

.Message
	min-width: 96vw
	border-radius: 3px
	padding: 1rem 2rem
	text-align: center

	+over-900px()
		min-width: 570px

	&.Message--error
		background: $color-light-red
		color: $color-dark-red

	&.Message--success
		background: $color-light-green
		color: $color-darker-green

@keyframes fadeInFadeOut
	0%
		opacity: 0
	10%
		opacity: 1
	90%
		opacity: 1
	100%
		opacity: 0
