@import "const.sass"


.BulkMoneyFirstStepLoading
    display: flex
    justify-content: center
    height: 90vh
    align-items: center
    width: 90%
    margin: auto
    right: 0
    left: 0
    flex-direction: column
    max-width: 80rem

    &__IntroRect
        margin-bottom: 2rem

.LoadingTableRow
    margin-bottom: 0.2rem
    height: 6rem
    +over-500px()
        height: 3rem
