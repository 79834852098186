@import "const.sass"

.DesktopAccountSwitcher
    margin: 0 1rem
    .DesktopAccountSwitcher__LandingAvatarContainer
        margin: auto
        display: flex
        cursor: pointer

    .DesktopAccountSwitcher__Dialog
        position: absolute
        right: 1rem
        top: 7rem
        .DesktopAccountSwitcher__Dialog__Content
            padding: 0
            width: 35rem
            max-height: 60rem
            overflow-y: scroll

            .DesktopAccountSwitcher__Dialog__Content__CurrentAccount
                padding: 1rem


            .DesktopAccountSwitcher__Dialog__Content__Divider
                background-color: $color-light-grey
                height: 0.1rem
                border: 0

            .DesktopAccountSwitcher__Dialog__Content__AccountSwitcher
                max-height: 25rem
                overflow-y: auto
                .AccountSwitcher__Header
                    margin: 1rem 2rem 2rem 1rem
                    color: $color-darker-grey-text

            .DesktopAccountSwitcher__Dialog__Content__LogoutContainer
                margin: 2rem
                .LogoutContainer__LogoutButton
                    border: none


.AccountSwitcher__ListItem
    display: flex
    flex-direction: row
    align-items: center
    margin-bottom: 1rem
    padding: 0.5rem
    &:hover
        background: $color-lighter-grey
        cursor: pointer
    .AccountSwitcher__ListItem__ImageContainer
        margin-right: 1rem
        .ImageContainer__Image
            width: 4rem
            height: 4rem
            border-radius: 50%
            object-fit: fill

.AccountSwitcher__ListItem__TextInfo
    p
        color: inherit
    .AccountSwitcher__ListItem__TextInfo__Organization
        font-weight: 600
.AccountSwitcher__ActiveListItem
    background: $color-lighter-grey
    color: $color-blue
    .AccountSwitcher__ListItem__TextInfo
        p
            color: inherit

.MobileAccountSwitcher__Header
    margin-bottom: 2rem
    color: $color-grey-text

.CurrentAccount__Content
    display: flex
    flex-direction: column
    align-items: center
    .CurrentAccount__Content__Image
        width: 6rem
        height: 6rem
        border-radius: 50%
        margin-bottom: 1rem
        object-fit: fill
        .CurrentAccount__Content__OrganizationName
            color: $color-black
