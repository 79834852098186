@import 'const.sass'

.DatePickerContainer
	display: flex
	align-items: center
	background: $color-white
	max-width: auto
	border: 1px solid $color-light-grey

.DatePickerContainer--dateValueContainer::-webkit-scrollbar
	display: none
.react-datepicker__close__icon
	margin-right: 0.5rem

.DatePickerContainer--dateValueContainer
	width: 100%
	height: 36px
	overflow-x: scroll
	overflow-y: hidden
	display: flex
	font-weight: 500
	font-size: 13px !important
	white-space: nowrap
	align-items: center
	justify-content: space-between
	padding-right: 3.5rem
	padding-left: 0.5rem
	color: $color-grey-text
	border: 1px solid $color-light-grey
		
		

