@import 'const.sass'

.FormElement
	position: relative
	margin-bottom: 3rem
	&.FormElement--standalone
		margin-bottom: 0
.Select
	appearance: none
	margin: 0.6rem 0 1rem 0
	transition: all 0.2s ease
	cursor: pointer
	background-repeat: no-repeat
	background-image: url('../../img/arrow-down.svg')
	background-color: $color-white
	color: $color-grey-text
	background-position: calc(100% - 1.2rem) 50%
	background-size: 16px
	border-radius: 3px
	padding-left: 10px
	border: 1px solid $color-light-grey
	font-weight: normal
	font-size: 13px
	line-height: 22px
	display: block
	height: 36px
	min-width: 195px

	&:focus
		outline: none

	&::placeholder
		color: $color-dark-grey

	&.Select--fit
		width: 100%

	&.Select--standalone
		margin: 0

	&.Select--invalid
		border: 1px solid $color-dark-red

	&.Select--disabled
		cursor: not-allowed
		opacity: 0.7

.Input
	margin: 0.6rem 0 1rem 0
	transition: all 0.2s ease
	background-color: $color-white
	color: $color-dark-grey
	border-radius: 3px !important
	padding: 1.2rem 3.6rem 1.2rem 1.2rem
	border: 1px solid
	border-color: $color-light-grey !important
	font-size: 13px !important
	line-height: 22px
	display: block
	height: 36px
	&:focus
		--tw-ring-color: transparent

	&:focus
		outline: none
		border: 1px solid $color-blue

	&::placeholder
		color: $color-dark-grey

	&.Input--fit
		width: 100%

	&.Input--invalid
		border: 1px solid $color-dark-red

	&.Input--disabled
		cursor: not-allowed
		opacity: 0.7
	&.Input--standalone
		margin: 0

.Textarea
	margin: 0.6rem 0 1rem 0
	transition: all 0.2s ease
	background-color: $color-white
	color: $color-dark-grey
	border-radius: 3px
	padding: 1.2rem 3.6rem 1.2rem 1.2rem
	border: 1px solid $color-light-grey
	font-size: 13px
	line-height: 22px
	display: block
	font-family: inherit

	&:focus
		outline: none
		border: 1px solid $color-blue

	&::placeholder
		color: $color-dark-grey

	&.Textarea--fit
		width: 100%

	&.Textarea--invalid
		border: 1px solid $color-dark-red

	&.Textarea--disabled
		cursor: not-allowed
		opacity: 0.7

.FormLabel
	color: $color-darker-grey
	font-weight: 500
	font-size: 13px
	line-height: 22px
	display: block

.Checkbox
	display: block
	margin-right: 1rem

	.Checkbox__container
		display: flex
		align-items: center
		margin-bottom: 1rem

	.Checkbox__label
		margin-left: 0.6rem
		font-size: 1.6rem
		line-height: 2.2rem
		color: $color-darker-grey

	.Checkbox__input
		appearance: none
		border: 1px solid $color-light-grey
		background: $color-white
		border-radius: 3px
		width: 1.8rem
		height: 1.8rem
		cursor: pointer
		transition: background-color 0.4s ease, border 0.4s ease
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0)

		&:focus
			outline: none

		&:disabled
			cursor: not-allowed

		&:checked
			background-color: $color-blue
			background-repeat: no-repeat
			background-size: 70%
			background-image: url('../../img/checkbox.svg')
			border-color: $color-blue
			animation-name: bump
			animation-duration: 0.4s
			animation-timing-function: ease
			background-position: center

		&:checked:disabled
			border-color: $color-light-grey
			background-color: $color-light-grey
			cursor: not-allowed

.RadioGroup

// 	.Radio
// 		margin-top: 0.6rem
// 		margin-bottom: 1rem

// 		&:first-of-type
// 			margin-left: 0

.Radio
	display: inline-block
	margin: 0 1rem

	.Radio__container
		display: flex
		align-items: center

	.Radio__label
		margin-left: 0.6rem
		font-weight: normal
		font-size: 13px
		line-height: 22px
		color: $color-darker-grey

	.Radio__input
		appearance: none
		border: 1px solid $color-light-grey
		background: $color-white
		border-radius: 50%
		width: 1.8rem
		height: 1.8rem
		cursor: pointer
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0)

		&:active
			outline: none

		&:focus
			outline: none

		&:disabled
			cursor: not-allowed

		&:checked
			background-color: $color-blue
			background-repeat: no-repeat
			background-size: 10px
			background-image: url('../../img/radio.svg')
			border-color: $color-blue
			animation-name: grow
			animation-duration: 0.4s
			animation-timing-function: ease
			background-position: center

		&:checked:disabled
			border-color: $color-light-grey
			background-color: $color-light-grey
			cursor: not-allowed

.Switch
	display: block
	margin-right: 1rem
	margin-bottom: 3rem

	.Switch__desc
		font-size: 1.3rem
		line-height: 2.2rem
		color: $color-dark-grey
		display: block
		margin-bottom: 1rem

	.Switch__label
		margin-left: 0.6rem
		font-size: 13px
		line-height: 22px
		width: 100%
		font-weight: 500
		color: $color-darker-grey
		vertical-align: middle

	.Switch__input
		appearance: none
		border: 1px solid $color-light-grey
		background-color: $color-light-grey
		background-repeat: no-repeat
		background-size: 12px
		background-image: url('../../img/radio.svg')
		border-radius: 9px
		width: 2.8rem
		height: 1.8rem
		cursor: pointer
		vertical-align: middle
		transition: all 0.4s ease
		background-position-y: center
		background-position-x: 2px
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0)

		&:focus
			outline: none

		&:disabled
			cursor: not-allowed

		&:checked
			background-color: $color-blue
			border-color: $color-blue
			animation-name: move
			animation-duration: 0.4s
			animation-timing-function: ease
			background-position-y: center
			background-position-x: 12px

		&:checked:disabled
			cursor: not-allowed

.ValidationError
	color: $color-input-error-red
	display: block
	line-height: 1.8rem
	font-size: 1.2rem
	max-height: 0
	overflow: hidden
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1)
	color: transparent
	position: absolute
	p
		line-height: 15px
		color: $color-input-error-red

	&.ValidationError--visible
		color: $color-input-error-red
		max-height: 100px
		margin-top: -0.8rem
		margin-bottom: 1rem
		transition: max-height 1s ease-in-out, color 1s ease

@keyframes move
	0%
		background-position-x: 2px

	100%
		background-position-x: 12px

@keyframes bump
	0%
		background-position-y: top

	50%
		background-position-y: bottom

	100%
		background-position-y: center

@keyframes grow
	0%
		background-size: 0

	20%
		background-size: 6px

	70%
		background-size: 12px

	100%
		background-size: 10px

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus
	-webkit-text-fill-color: $color-dark-grey
	transition: background-color
