$color-blue-dark: #193680
$color-blue: #3B57F7
$color-blue-light: #DFEAFD
$color-black: #333
$color-dark-grey: #84879A
$color-grey-text: #84879A
$color-disabled-grey-text: #61657f
$color-darker-grey: #25262D
$color-darker-grey-text: #6b6f86
$color-mild-dark-grey: #A3A5B0
$color-light-grey: #E3E5EB
$color-lighter-grey: #F3F4F7
$color-white: #FFFFFF
$color-dark-green: #067F43
$color-darker-green: #007e40
$color-green: #24F08A
$color-light-green: #BFFCDE
$color-darker-red: #AE0D0D
$color-dark-red: #F32A2A
$color-input-error-red: #ee0000
$color-light-red: #FDCECE
$color-yellow: #FFEA00

=over-350px()
	@media screen and (min-width: 350px)
		@content

=over-400px()
	@media screen and (min-width: 400px)
		@content

=over-500px()
	@media screen and (min-width: 500px)
		@content

=under-500px()
	@media screen and (max-width: 500px)
		@content

=over-600px()
	@media screen and (min-width: 600px)
		@content
=over-650px()
	@media screen and (min-width: 650px)
		@content

=over-700px()
	@media screen and (min-width: 700px)
		@content

=over-800px()
	@media screen and (min-width: 800px)
		@content

=over-900px()
	@media screen and (min-width: 900px)
		@content

=over-1200px()
	@media screen and (min-width: 1200px)
		@content

=over-1260px()
	@media screen and (min-width: 1260px)
		@content

=over-1300px()
	@media screen and (min-width: 1300px)
		@content

=over-1338px()
	@media screen and (min-width: 1338px)
		@content

=over-1360px()
	@media screen and (min-width: 1360px)
		@content

=over-1460px()
	@media screen and (min-width: 1460px)
		@content

=over-1550px()
	@media screen and ( min-width: 1550px )
		@content

=over-1600px()
	@media screen and ( min-width: 1600px )
		@content
=over-1700px()
	@media screen and ( min-width: 1700px )
		@content

=over-1800px()
	@media screen and (min-width: 1800px)
		@content

h1, h2, h3, h4
	font-weight: 600
	color: $color-blue-dark

h1
	font-size: 3.2rem
	line-height: 4rem
	margin-top: 3rem
	margin-bottom: 2rem

	+over-900px()
		font-size: 3.6rem
		line-height: 5rem

h2
	font-size: 2.2rem
	line-height: 2.8rem
	margin-top: 2rem
	margin-bottom: 1rem

	+over-900px()
		font-size: 3.2rem
		line-height: 4.5rem

h3, h4
	font-size: 1.8rem
	line-height: 2.6rem
	margin-top: 1rem
	margin-bottom: 0.5rem

	+over-900px()
		font-size: 2.4rem
		line-height: 3.2rem

h5
	font-size: 1.6rem
	line-height: 2.8rem
	font-weight: 600
	color: $color-darker-grey
	margin-top: 1rem
	margin-bottom: 0.5rem

a
	font-size: 13px
	line-height: 22px
	text-decoration: none
	color: $color-blue
	font-weight: 600

small
	font-size: 1.3rem
	line-height: 2.2rem

p
	color: $color-grey-text
	font-weight: normal
	font-size: 13px
	line-height: 22px
