@import 'const.sass'

.Modal
	top: 0
	right: 0
	position: fixed
	height: 110vh
	width: 100vw
	z-index: 9999
	margin: auto
	animation: fadeIn 0.2s
	display: flex
	justify-content: center
	align-items: center
	overflow-y: scroll
	background-color: rgba(255, 255, 255, 0.5)

	.Modal__content
		width: 90%
		border: 1px solid $color-light-grey
		border-radius: 3px
		padding: 3rem
		box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04)
		background: $color-white
		+over-600px()
			width: 500px
		&:focus
			outline: 0

		&.Modal__content--rightAlign
			padding: 0
			position: absolute
			top: 10rem
			margin: auto
			+over-700px()
				right: 4rem
				width: 45rem
		&.Modal__content--wide
			padding: 0

	.Modal__text
		padding-left: 3rem
		border-left: 3px solid $color-light-grey
		margin-bottom: 2rem

	.Modal__title
		color: $color-darker-grey
		margin-bottom: 5rem
		line-height: 2.8rem
		font-size: 13px
		line-height: 22px

@keyframes fadeIn
	0%
		opacity: 0
	100%
		opacity: 1



.Header__Container
	display: flex
	margin: 2rem
	align-items: center
	justify-content: space-between

	.Header__Container__Icon
		border: none
	.Header__Container__Title
		line-height: 0
		font-weight: bold
		font-size: 13px
		color: $color-grey-text

.Header__Container__divider
	background-color: $color-light-grey
	height: 0.1rem
	border: 0
	margin: 2rem 0

