@import 'const.sass'

.ArrowLink
	color: $color-blue
	font-weight: 500
	font-size: 13px
	line-height: 22px
	display: inline-block
	margin-top: 1rem
	cursor: pointer

	&.ArrowLink--small
		font-size: 1.3rem
		line-height: 2.2rem

		svg
			width: 1.3rem
			height: 1.3rem

	.ArrowLink__content
		vertical-align: middle

	.ArrowLink__arrow
		margin-left: 0.5rem
		margin-right: 1rem
		vertical-align: middle
