@import "const.sass"


.SecondScreenContainer
    margin: 2rem
.SecondScreenHeader
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 1rem
    margin-bottom: 3rem
    .SecondScreenHeader__Header
        margin-bottom: 1rem


.ThirdAndFourthStepContainer
    display: grid
    grid-template-rows: 1fr
    grid-row-gap: 1rem
    margin: auto
    max-width: 80rem
    min-height: 90vh
    align-items: center

    .ThirdAndFourthStepContainer__spacing
        margin-bottom: 1rem

.FifthandSendMessageContainer
    display: flex
    justify-content: center
    height: 90vh

.AttachmentsContainer
    display: flex
    height: 90vh
    justify-content: center


.SendSMSFirstStepLoading
    display: flex
    justify-content: center
    height: 90vh
    align-items: center
    width: 90%
    margin: auto
    right: 0
    left: 0
    flex-direction: column
    max-width: 80rem

    .SendSMSFirstStepLoading__IntroRect
        margin-bottom: 2rem
