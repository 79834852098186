@import 'const.sass'


.ButtonLinkComponent
  color: $color-blue
  font-size: 13px
  font-weight: 600
  display: inline-block
  margin-top: 1rem
  border: none
  background: transparent
  cursor: pointer
  &:focus
    outline: none

  &.ButtonLinkComponent--small
    font-size: 13px
    line-height: 22px

    svg
      width: 1.3rem
      height: 1.3rem

  .ButtonLinkComponent__content
    vertical-align: middle

  .ButtonLinkComponent__arrow
    margin-left: 0.5rem
    margin-right: 1rem
    vertical-align: middle
    width: 2rem
    height: 2rem

