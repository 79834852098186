@import 'const.sass'

.SignupContainer
    display: grid
    align-items: center
    grid-template-columns: 1fr
    justify-self: center
    align-items: center
    min-height: 80vh
    +over-1200px()
        justify-items: center
        grid-gap: 10rem
        margin: 4rem
        margin-top: 0
        margin-bottom: 2rem
        grid-template-columns: 2fr 1fr

.RegistrationContainer
    margin: 0 3rem
    +over-600px()
        margin: auto
        margin-top: 0
    .Checkbox
        margin-bottom: 2.5rem

    .RegistrationContainer__dividerText
        margin-bottom: 1.75rem
        text-align: center

    .RegistrationContainer__header
        margin-bottom: 2rem
        margin-left: 1rem
        margin-top: 1.5rem
        font-size: 18px
        line-height: 0
        color: $color-darker-grey


.FormElementsGroup
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-around
    margin-bottom: 3rem
    +over-600px()
        flex-direction: row
    .FormElement
        width: 100%
        +over-600px()
            margin: 0 1rem 0 1rem
    button
        width: 100%

    .FormElementsGroup__LoadingSelect
        display : flex
        width: 100%
        flex-direction: column

        .FormElementsGroup__LoadingSelect__Placeholder
            margin-bottom: 0.4rem

.InfoContainer
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 1fr
    grid-gap: 10px
    justify-self: center
    justify-items: center
    min-height: 40vh
    max-width: 400px
    margin: 0.32rem 0.6rem 3rem 0.6rem
    grid-row: 1
    +over-1200px()
        grid-row: auto
        margin-top: 0
        margin-bottom: 10rem
        justify-self: flex-start

    .InfoContainer__ContentContainer
        justify-content: center
        display: grid
        grid-gap: 1.25rem
        border-radius: 10px
        .InfoContainer__ContentContainer__Heading
            font-size: 2.4rem
            line-height: 3.2rem
        .InfoContainer__ContentContainer__iconContainer
            align-self: center
            justify-self: center
    .InfoContainer__ContentContainer__logo__image
        display: none
        +over-1200px()
            display: block

    .InfoContainer__navigationIconContainer
        display: grid
        grid-template-columns: 1fr 1fr
        grid-gap: 3rem
        svg
            cursor: pointer

.AutowidthButtonsGroup
    button
        width: 100%
        margin: 1rem
        +over-1200px()
            margin-top: 0

.Missedsomething
    cursor: pointer
    span
        color: $color-blue

.RegistrationLoginLink__Container
    color: $color-grey-text
    font-weight: normal
    font-size: 11px
    line-height: 18px
.RegistrationLoginLink__Link
    font-weight: normal
    font-size: 11px
    line-height: 18px     

.FormContainer__Element
    max-width: 52rem
    .FormContainer__Element__InputGroup
        margin-bottom: 0
        +over-600px()
            margin-bottom: 0.5rem
        .FormContainer__Element__InputGroup__Item
            margin-bottom: 0.5rem
            +over-600px()
                margin-bottom: 0

.PasswordContainer__Field, .NormalInputContainer__Field
    input
        min-width: auto
    select
        min-width: auto

.PasswordContainer__IconButton
    align-self: center
    margin-left: 1.5rem !important
    +over-600px()
        margin-top: 10px !important
        margin-left: 0rem !important
    +over-600px()
        margin-left: 0
    button
        border: none

