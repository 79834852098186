@import 'const.sass'

.IconAction
	background: none
	border: 0
	cursor: pointer
	border: 1px solid $color-light-grey
	border-radius: 3px
	padding: 0.7rem
	display: inline-block

	&:focus
		outline: 0

	&.IconAction--with-siblings
		margin-right: 1rem
	
	&.IconAction--disabled
		background: $color-light-grey
		cursor: not-allowed


	svg
		display: inline-block
		vertical-align: middle
		width: 22px
		height: 22px
		
.airtel_path-1
	fill:#84879A
