
.DefaultErrorComponent
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    .DefaultErrorComponent__Children
        margin-top: 1rem
        margin-bottom: 2rem
        text-align: center
