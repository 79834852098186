@import "const.sass"
.TagsComponent__Container
  margin-bottom: 3rem
  .TagsComponent__Container__Label
    color: $color-black
    margin-bottom: 1rem
  .react-tags
    position: relative
    padding: 6px 0 0 6px
    border: 1px solid $color-light-grey
    border-radius: 1px

    /* shared font styles
    font-size: 13px
    line-height: 1.2

    /* clicking anywhere will focus the input
    cursor: text

    &.is-focused
      border-color: #b1b1b1

  .react-tags__selected
    display: inline

  .react-tags__selected-tag
    display: inline-block
    box-sizing: border-box
    margin: 0 6px 6px 0
    padding: 3px 3px
    border: 1px solid #d1d1d1
    border-radius: 5px
    background: #f3f4f7
    color: $color-dark-grey
    font-size: 13px
    line-height: 18px

    &:after
      content: "\2715"
      color: #aaa
      margin-left: 8px

    &:hover, &:focus
      border-color: #b1b1b1

  .react-tags__search
    display: inline-block
    padding: 3px 2px
    margin-bottom: 6px


  .react-tags__search-input
    width: 100%

    /* remove styles and layout from this element
    margin: 0
    padding: 0
    border: 0
    outline: none

    /* match the font styles
    font-size: inherit
    line-height: inherit

    &::-ms-clear
      display: none

  .react-tags__suggestions
    position: absolute
    top: 100%
    left: 0
    width: 100%
    z-index: 1000000


    ul
      margin: 4px -1px
      padding: 0
      list-style: none
      background: white
      border: 1px solid $color-dark-grey
      border-radius: 2px
      box-shadow: 0 2px 6px $color-black

    li
      border-bottom: 1px solid $color-light-grey
      padding: 1px
      min-height: 36px
      display: flex
      align-items: center

      mark
        text-decoration: underline
        background: none
        font-weight: 600

      &:hover
        cursor: pointer
        background: #eee

      &.is-active
        background: #b7cfe0

      &.is-disabled
        opacity: 0.5
        cursor: not-allowed
