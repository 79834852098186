@import "const.sass"

.Container
    position: fixed
    z-index: 1
    left: 0px
    top: 0px
    padding: 3px

    .AlertContainer
        width: 100vw
        height: 100vh
        padding: 3px
        display: flex

        .AlertComponent
            margin: auto

            margin-top: 15%
            background: $color-white
            border: 1px solid $color-light-grey
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04)
            border-radius: 3px
            padding: 1rem
            transition: all 0.5s ease-in-out
