@import "const.sass"

.LoadingSettingsContainer
    margin: 2rem
    .LoadingSettingsContainer__Header
        .LoadingSettingsContainer__Header__Tabs
            margin-bottom: 2rem
            display: none
            +over-700px()
                display: block
    .LoadingSettingsContainer__Body
        display: flex
        flex-direction: column
        justify-content: space-around
        +over-700px()
            flex-direction: row
        .LoadingSettingsContainer__Body__Item
            width: 100%
            margin-bottom: 2rem
            +over-700px()
                width: 45%
