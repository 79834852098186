@import "const.sass"


.LoadingTableRow
    margin-bottom: 0.2rem
    height: 6rem
    +over-500px()
        height: 3rem


.FullpagetableContainer
    margin: 2rem
    .FullpagetableContainer__Header
        display: flex
        justify-content: space-between
        flex-direction: column
        margin-bottom: 3rem
        +over-700px()
            flex-direction: row

        .FullpagetableContainer__Header__Item
            height: 3.6rem
            width: 100%
            margin-bottom: 0.5rem
            +over-700px()
                width: 30%
                




