@import 'const.sass'

.Collapsible

	.Collapsible__text
		padding: 3rem
		border-top: 1px solid $color-light-grey
		color: $color-grey-text

	.Collapsible__item
		margin-bottom: 1rem
		border: 1px solid $color-light-grey
		border-radius: 3px

	.Collapsible__content
		max-height: 0
		overflow: hidden
		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1)
		background: $color-white

	.Collapsible__title
		width: 100%
		padding: 2rem 3rem
		background-color: $color-white
		border: none
		appearance: none
		cursor: pointer
		font-size: 13px
		line-height: 2.8rem
		text-decoration: none
		color: $color-blue
		font-weight: 600
		text-align: left
		transition: border 0.5s ease-in-out
		display: flex
		align-items: center
		justify-content: space-between

		svg
			transition: transform 0.3s ease-in-out
			transform-origin: center

		&:focus
			outline: 0

	.Collapsible__item--open

		.Collapsible__title svg
			transform: rotate(180deg)

		.Collapsible__content
			max-height: 9999px
			transition: max-height 1s ease-in-out
