.Grid__Container
    box-sizing: border-box
    display: flex
    flex-wrap: wrap
    width: 100%
    
    .Grid__Item
        box-sizing: border-box
        margin: 0

