@import "const.sass"

.CentralPaperContainer
    padding: 0
    max-width: 62rem
    margin: auto

    .CentralPaperContainer__divider
        background-color: $color-light-grey
        height: 0.1rem
        border: 0

    .CentralPaperContainer__content
        padding: 3rem
        .CentralPaperContainer__content__header
            color: $color-black
            font-weight: bold
            font-size: 18px
            line-height: 22px
            margin-top: 0rem
            margin-bottom: 0.5rem

