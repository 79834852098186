@import "const.sass"

.SurveyContainerLoading
    display: flex
    justify-content: center
    height: 100%
    width: 100%
    right: 0
    left: 0

.SurveyDetailContainerLoading
    display: flex
    justify-content: space-between
    height: 100%
    width: 100%
    right: 0
    left: 0
    .DetailComponent
        margin-right: 1rem

.SurveyQuestionsContainerLoading
    justify-content: center
    height: 100%
    width: 100%
    right: 0
    left: 0
    .QuestionsComponent
        margin: auto

.SurveyPageLoading
    justify-content: center
    height: 100vh
    align-items: center
    width: 90%
    margin: auto
    right: 0
    left: 0

.SurveyNavLoading
    display: flex
    width: 100%
    padding: 5px 0px
    height: 7%

.surveyQuetionFooterLoading
    display: flex
    padding: 5px 0px
    height: 7%
    justify-content: space-between
    width: 60%

.SurveyNavLoadingTab
    margin: 0px 2px

.SendSurveyContainerLoading
    display: flex
    justify-content: space-between
    height: 100%
    width: 100%
    right: 0
    left: 0
