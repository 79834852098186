@import 'const.sass'

.Questionmark
	width: 24px
	height: 24px
	background: $color-blue-light
	border-radius: 50%
	display: flex
	align-items: center
	justify-content: center

.TooltipContainer
	display: inline-block
	position: relative

.TooltipContent
	display: none
	position: absolute
	padding-left: 4rem
	top: -10px

	&.TooltipContent--visible
		display: block

	.TooltipContent__inner
		border-radius: 3px
		color: $color-blue-dark
		padding: 1.2rem
		min-width: 300px
		background: $color-blue-light
		border: 1px solid #c6d4ff
		box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04)

		.TooltipContent__title
			color: $color-blue-dark

		&:before
			content: ""
			position: absolute
			width: 7px
			height: 10px
			background-repeat: no-repeat
			background-image: url('../../img/tooltip-arrow.svg')
			top: 18px
			left: 34px
