@import "const.sass"
.ChipContainer
    display: inline-flex
    background: $color-lighter-grey
    color: $color-grey-text
    font-size: 13px
    align-items: center
    padding: 0.4rem
    border-radius: 0.3rem

    .ChipContainer--delete
        border: none
