@import 'const.sass'

.Button
	color: $color-white
	background: $color-blue
	padding: 0.7rem 1.5rem
	border-radius: 3px
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04)
	transition: all 0.2s ease-out
	display: inline-block
	line-height: 2.2rem
	font-size: 1.3rem
	font-weight: 400
	vertical-align: middle
	cursor: pointer
	border: 1px solid $color-blue
	height: 3.6rem

	&.Button--fit
		width: 100%

	&.Button--link
		display: flex
		align-items: center
		justify-content: center

	&:focus
		outline: 0

	&:hover
		background: $color-blue-dark
		border-color: $color-blue-dark
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15)

	&.Button--outline
		background: transparent
		border: 1px solid $color-blue
		color: $color-blue

		&:hover
			background: $color-white
			border-color: $color-blue
			box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15)

	&.Button--with-siblings
		margin-right: 1rem

	&.Button--dark
		background: $color-grey-text
		border: 1px solid $color-grey-text

		&:hover
			background: $color-grey-text
			border-color: $color-grey-text
			box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15)

	&.Button--green
		background: $color-dark-green
		border: 1px solid $color-dark-green

		&:hover
			background: $color-dark-green
			border: 1px solid $color-dark-green
			box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15)

	&.Button--grey
		background: transparent
		border: 1px solid $color-light-grey
		color: $color-darker-grey-text

		&:hover
			background: transparent
			border-color: $color-light-grey
			box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15)

	&.Button--disabled
		color: $color-disabled-grey-text
		background: $color-light-grey
		border: 1px solid $color-light-grey

		&:hover
			background: $color-light-grey
			border-color: $color-light-grey
			box-shadow: none
			cursor: not-allowed

	&.Button--blue-border
		color: $color-blue
		border-color: $color-blue
		background: transparent
		svg, path
			stroke: $color-blue

	&.Button--grey-border
		color: $color-disabled-grey-text
		border-color: $color-disabled-grey-text
		background: transparent
		svg, path
			stroke: $color-disabled-grey-text

	.Button__content
		display: flex
		align-items: center
		justify-content: center
		color: inherit

	.Button__icon--before
		margin-right: 1.3rem
		width: 1.2rem
		height: 1.2rem

	.Button__icon--after
		margin-left: 1.3rem
		width: 1.2rem
		height: 1.2rem
