@import "const.sass"

.AttachmentsContainer
    display: grid
    align-items: center
    min-height: 90vh
    margin: 0 1rem 0 1rem
    grid-template-columns: repeat(1, 1fr)
    justify-content: center
    justify-items: center
    +over-900px()
        grid-template-columns: repeat(2, 1fr)
    +over-1200px()
        grid-template-columns: repeat(3, 1fr)

    .AttachmentsContainer__LoadingDivider
        +over-900px()
            margin-left: 2rem
    .AttachmentsContainer__Illustration
        justify-self: center
        align-items: center
        max-width: 30rem
        max-hwight: 30rem
        +over-900px()
            justify-self: flex-end

        .AttachmentsContainer__Illustration__Img
            border-radius: 1rem
            width: 100%
            height: 100%
            object-fit: fill
        
    .AttachmentsContainer__Content
        +over-900px()
            width: 80%

        

        .AttachmentsContainer__Content__PoweredBy
            display: flex
            justify-content: center
            align-items: center
            margin-top: 2rem

            .AttachmentsContainer__Content__PoweredBy__Title
                margin-right: 2rem


        .AttachmentsContainer__Content__Paper
            padding: 0

            .AttachmentsContainer__Content__Paper__Divider
                background-color: $color-light-grey
                height: 0.1rem
                border: 0

        .AttachmentsContainer__Content__Container
            padding: 4rem

            .Content__Container__title
                color: $color-black
                font-size: 1.8rem

            .Content__Container__Info
                font-size: 1.3rem
                color: $color-black
                margin-bottom: 1rem



            .Content_Container__Username
                font-size: 3rem

            .AttachmentsContainer__Content__Container__Input[disabled]
                background: $color-light-grey

            .AttachmentsContainer__Content__Container__File
                margin-top: 2rem
                justify-content: center
                height: 4.8rem


.FileErrorComponent__Container
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center

    svg
        max-width: 100%
    
    .FileErrorComponent__text
        margin-top: 1rem
        text-align: center

    .FileErrorComponent__Actions
        margin-top: 2rem
