@import 'const.sass'

.LoginContainer__form__Header
    font-size: 18px
    line-height: 0rem
    margin-bottom: 3rem
    color: $color-darker-grey
.LoginContainer__InputContainer
    margin-bottom: 30px
.LoginContainer__form__password__container
    display: grid
    grid-template-columns: 90% auto
    grid-column-gap: 1rem

    .FormElement
        width: 100%

    .LoginContainer__form__password__container__Togglepasswordvisibility, .Toggle__password__visibility
        border: none
        align-self: flex-end
        margin-bottom: 1.5rem
    .Toggle__password__visibility__position__center
        align-self: center
        margin-top: 1.5rem

.LoginContainer__form__more__links
    text-align: center
    p
        font-size: 11px
        line-height: 18px
.LoginContainer__form__more__links__link
    color: $color-blue
    cursor: pointer
    font-weight: normal
    font-size: 11px
    line-height: 18px

.LoginContainer__form__more__links__FirstContainer
    margin-bottom: 6px

.LoginContainer__form__more__links__auxilliary-links
    display: flex
    flex-direction: column
    margin: 2rem 0 2rem 0
    align-items: center
    justify-content: space-around
    +over-600px()
        flex-direction: row


.LoginLoadingContainer
    display: grid
    align-items: center
    min-height: 90vh
    max-width: 110rem
    margin: auto
    grid-template-columns: repeat(1, 1fr)
    justify-content: center
    grid-row-gap: 1rem
    grid-column-gap: 1rem
    +over-900px()
        grid-template-columns: repeat(3, 1fr)

