@import "const.sass"

.BannerContainer
    max-width: 80rem
    margin: auto
    margin-bottom: 1.5rem
    padding: 2rem
    border-radius: 0.7rem
    width: 95%
    +over-800px()
        margin: 0 auto 1.5rem auto
        width: 100%
    p
        color: $color-white
        line-height: 1.8rem
        font-weight: bold

        a
            text-decoration: underline
    
    &.BannerContainer--announcement
        background-color: $color-blue-light
        p
            color: $color-blue-dark
    &.BannerContainer--issue
        background-color: #f65556
