@import 'const.sass'
@tailwind base
@tailwind components
@tailwind utilities
@import 'ui-common/Button/style.sass'

// Master grid
.App
	display: grid
	grid-template-rows: [header] 100px [body] auto [footer] 100px

.Header
	grid-row: header

.Footer
	grid-row: footer
	margin-top: 3rem

.App__content
	grid-row: body
	overflow-x: scroll
	min-height: 80vh
// Content grid
.ContentGrid
	min-height: calc(100vh - 300px)
	grid-row: body
	display: grid
	grid-auto-rows: minmax(0, auto)
	grid-template-areas: "col-1" "col-2" "col-3" "col-4" "col-5" "col-6"
	grid-gap: 2rem
	margin: 0 5%

	+over-900px()
		grid-template-areas: "col-1 col-2 col-3 col-4 col-5 col-6"
		grid-template-columns: repeat(6, 1fr)

.MaintainanceMode__Container
	display: flex
	margin: auto
	justify-content: center
	min-height: 100vh
	align-items: center
