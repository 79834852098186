@import 'const.sass'

.MultiSelect
	width: 100%
	margin-bottom: 3rem
	.MultiSelect__label
		color: $color-darker-grey
		font-weight: 500
		font-size: 13px
		line-height: 22px
		display: block

	.MultiSelect__select
		width: 100%
		appearance: none
		margin: 0.6rem 0 1rem 0
		cursor: pointer
		background-repeat: no-repeat
		background-image: url('../../img/arrow-down.svg')
		background-color: $color-white
		color: $color-dark-grey
		background-position: calc(100% - 1.2rem) 50%
		background-size: 16px
		border-radius: 3px
		border: 1px solid $color-light-grey
		font-size: 13px
		line-height: 22px
		display: inline-block
		font-weight: 400
		padding: 0.6rem 3.6rem 0 1.2rem
		height: 36px

	.MultiSelect__select-content
		display: flex
		width: 100%
		overflow: scroll
		padding-bottom: 1.2rem
		&::-webkit-scrollbar
			display: none
		-ms-overflow-style: none
		scrollbar-width: none

	.MultiSelect__select-item
		display: flex
		align-items: center
		white-space: nowrap
		background: $color-lighter-grey
		border-radius: 3px
		margin-right: 0.5rem
		padding: 0 1rem
		color: $color-grey-text
		font-size: 1.4rem
		cursor: default

	svg
		cursor: pointer

	.MultiSelect__list
		position: absolute
		list-style: none
		min-width: 33rem
		max-height: 30rem
		border: 1px solid $color-light-grey
		z-index: 1
		background: $color-white
		overflow: scroll
		border-radius: 3px
		&::-webkit-scrollbar
			display: none
		-ms-overflow-style: none
		scrollbar-width: none
		
	.MultiSelect__list-item
		padding: 1rem
		border-bottom: 1px solid $color-light-grey
		cursor: pointer

		&:last-of-type
			border: 0

		&:nth-of-type(odd)
			background: $color-lighter-grey

	.Checkbox__container
		margin: 0

	.Checkbox__label
		font-size: 1.4rem
