@import "const.sass"

.MessageDetails
    width: 100%
    padding: 2rem

    .MessageDetails__BackButton
        margin-bottom: 2rem
        .MessageDetails__Buttons_Container
            max-width: 100rem
            +over-500px()
                display: flex
            display: block 
            margin: auto
            justify-content: space-between
            .MessageDetails__Buttons_Container_BackNnextBtns
                display: flex
                @media (max-width: 500px)
                    justify-content: space-between
                    margin: 1rem 0rem 1rem 0rem
                .MessageDetails__Buttons_Container_nextBtn
                    margin-right: 2rem
    .MessageDetailsContainer
        display: flex
        flex-direction: column
        margin-top: 2rem
        margin-bottom: 5rem
        max-width: 100rem
        margin: auto
        
        .MessageDetails__ChatBubble
            display: flex
            margin-top: 1rem
            
        .MessageDetails__Container
            margin-bottom: 2rem
            display: flex
            justify-content: space-between
            flex-direction: column
            .MessageDetails__Container__MessageHeader   
                font-size: 15px
                color: $color-darker-grey
                font-weight: 500
                line-height: 0
            .MessageDetails__Container__Heading
                display: flex
                align-items: center
                justify-content: space-between
                .MessageDetails__Container__Heading__Date
                    background-color: $color-light-grey
                    padding: 5px
                    border-radius: 5px
                    font-size: 13px

            .MessageDetails__SenderID
                font-size: 13px
                color: $color-darker-grey
                 
            .MessageDetails__Header
                font-size: 16px
                line-height: 22px
                font-weight: 400
                color: $color-darker-grey
                margin-top: 1rem
                margin-bottom: 0.5rem
                .MessageDetails__Header__Value
                    font-weight: 600
                .MessageDetails__Cost__Value
                    color: $color-blue
    .MessageDetailsContainer__RecipientsContainer
        margin-top: 2rem
        +over-900px()
            margin-top: 0
            
    .MessageDetailsContainer__ReportsContainer
        width: 100%
        position: relative
        padding: 1rem
        max-width: 70vw
        +over-500px()
            max-width: 100%

        .ReportsContainer__ChipsContainer
            margin-top: 2rem
            font-size: 13px
            .ChipsContainer__Chip
                margin-bottom: 2rem
                +over-700px()
                    margin-bottom: 1rem
        .ReportsContainer__SubInfo
            margin: 1rem 0

        .ReportsContainer__ErrorContainer
            width: 100%
            display: flex
            align-items: center
            flex-direction: column

        .ReportsContainerTable__Prop
            color: $color-blue
        .SelectTelcoContainer
            display: flex
            justify-content: flex-end
            select
                width: 100%

.MessageDetailsContainer__MessageStats
    .MessageDetailsContainer__MessageStats__MessagesTable
        width: 100%
        position: relative
        margin: auto
        margin-top: 1rem
        +over-500px()
            max-width: 100%
    .MessageDetailsContainer__MessageStats__RecipientsHeader
        margin-bottom: 2rem
        font-size: 13px
        line-height: 22px
        font-weight: 400
        color: $color-darker-grey
        margin-top: 1rem
        margin-bottom: 0.5rem

    .MessagesTable__Prop
        color: $color-blue     

.Speech__bubble
  position: relative
  background: #3b57f7
  border-radius: .4em
  padding: 0.7rem
  max-width: 40rem
  min-width: 10rem

  &:after
    content: ''
    position: absolute
    right: 0
    top: 50%
    width: 0
    height: 0
    border: 23px solid transparent
    border-left-color: #3b57f7
    border-right: 0
    border-top: 0
    margin-top: -11.5px
    margin-right: -23px

.Speech__bubble__text
    color: $color-white

.MessageDetailsHeader
    display: flex
    flex-direction: column
    justify-content: flex-end
    margin-top: 2rem
    +over-600px()
        flex-direction: row
    .MessageDetailsHeader__SearchBox
        width: 100%
        +over-600px()
            margin-top: 0
            width: auto
    .MessageDetailsHeader__Actions
        display: flex
        flex-direction: column
        +over-600px()
            flex-direction: row
        
        .MessageDetailsHeader__Actions__Button
            margin-right: 0.5rem
            margin-bottom: 1rem
            +over-600px()
                margin-bottom: 0
        input
            width: 100%

        
.TableRowPadding
    padding-left: 1rem

.MessageNotFoundContainer, .RecipientsNotFoundContainer
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center

    .RecipientsNotFoundContainer__Content
        text-align: center
        margin-bottom: 2rem


.DeleteScheduledMessageActions
    display: flex
    justify-content: flex-start
    margin-top: 2rem
    .DeleteScheduledMessageActions__Action
        margin-right: 1rem

.MessageDetailsContainer__FirstLoadingIndicator
    margin-bottom: 2rem

.back_N_next_OutBox_SMSBtns__footer_section, .back_N_next_Survey_OutBox_Btns__footer_section
    display: flex
    justify-content: space-between
    margin-top: 1rem
