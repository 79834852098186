@import "const.sass"

.LoadingContainer
  background: $color-white
  border: 1px solid
  border-color: $color-light-grey
  animation-name: color-change
  animation-duration: 2s
  animation-iteration-count: infinite
  animation-timing-function: linear
  position: relative
  
  &.LoadingContainer--rect
    height: 5rem
  &.LoadingContainer--circle
      width: 4rem
      height: 4rem
      border-radius: 50%
  &.LoadingContainer--text
      height: 2rem

@keyframes color-change
  from
    background-color: #CCC
  to
    background-color: #f6f7f8
