@import "const.sass"

.PasswordComponent__Container
    display: flex
    width: 100%
    align-items: center
    .FormElement
        width: 100%
    .PasswordContainer__inputContainer
        margin-left: -35px
        margin-top: -25px
        z-index: 100
        svg
            width: 12px
            height: 12px
            stroke: #84879A
        
    .PasswordContainer__input__icon
        margin-top: 1.1rem
        width: 12px
        height: 12px
        border: none
        margin-right: -30px
        
